<template>
  <v-container class="mx-auto my-auto">
    <loading :dialog="true" :loading="true" message="Processing subscription..." />
  </v-container>
</template>

<script>

import SegmentTracking from "@/services/SegmentTracking";
import StoreConstants from "@/StoreConstants";
import Loading from "@/components/Loading";

let intervalHandler = null

export default {
  name: "PostPayment",
  components: {Loading},
  data() {
    return {
      waitForSubscription: true,
      intervalCount: 0,
      maxCalls: 30
    }
  },
  methods: {
    async check() {
      try {

        if(this.$store.getters.isSubscribed) {
          clearInterval(intervalHandler)

          this.$router.replace({
            path: 'setup',
            query: {
              justSubscribed: true
            }
          })

        } else {
          this.$store.dispatch(StoreConstants.actions.loadAccount)
          this.intervalCount++

          if(this.intervalCount >= this.maxCalls) {
            clearInterval(intervalHandler)
            alert('There seems to be a delay processing the subscription.  Please try again.  If it repeatedly occurs contact support@truple.io.')
            location.href = `${window.location.origin}/account?subscribe=true`
          }
        }

      } catch(e) {
        console.log(e)
      }
    }
  },
  mounted() {
    if(this.$route.query.newSubscription === 'true') {
      if(this.$route.query.subscriptionAmount) {
        let amount = parseInt(this.$route.query.subscriptionAmount)
        let plan = this.$route.query.subscriptionPlan

        SegmentTracking.track("Subscribe", {
          price: amount,
          category: 'Checkout',
          label: plan,
          value: amount,
          currency: 'USD'
        })
      }
    }

    this.waitForSubscription = this.$route.query.waitForSubscription === 'true'
    if(this.waitForSubscription) {
      intervalHandler = setInterval(this.check, 1000)
    } else {
      this.$store.dispatch(StoreConstants.actions.loadAccount)
      //subscription upgrades don't need to wait
      this.$router.replace('account')
    }
  }
}
</script>

<style scoped>

</style>